import * as moment from "moment-timezone";
import { APP_DEFAULTS } from "@/configs";

export const HumanDiff = {
  methods: {
    humanDiff(value, suffix = false, tz = APP_DEFAULTS.DEFAULT_TIMEZONE) {
      if (!value) {
        return null;
      }

      const realSeconds = moment.tz(String(value), tz).diff(moment(), 'seconds');

      return moment.duration(realSeconds, 'seconds').humanize(suffix, {ss: -1});
    }
  }
}

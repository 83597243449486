import { Preferences } from '@capacitor/preferences';

export const RadiusMixin =  {
  data() {
    return {
      radius: 200,
      radiusSliderOpened: false,
    }
  },
  methods: {
    async initialRadius(key) {
      const radius = await Preferences.get({ key: key });

      const newRadius = radius.value;
      if(newRadius) {
        this.radius = JSON.parse(newRadius);
      }
    },
    async changeRadius(event, key) {
        this.radius = event.target.value

        await Preferences.set({ key: key, value: JSON.stringify(this.radius) });
    }
  },
}
<template>
  <ion-list>
    <ion-list-header>
      <ion-label>{{ title }}</ion-label>
    </ion-list-header>
    <ion-item v-for="item in items" :key="item.id" @click="clickItem(item)">
      {{ item.title }}
      <ion-icon v-if="item.system_name == selected" slot="end" name="checkmark" />
    </ion-item>
  </ion-list>
</template>

<script>
    export default {
      name: 'Popover',
      props: {
        title: { Type: String, default: 'Selecteren' },
        items: { type: Array, },
        selected: { type: String },
        dismiss: { type: Function, required: true },
        selectItem: { type: Function, required: true }
      },
      methods: {
        clickItem(item) {
          this.selectItem(item);
          this.dismiss()
        }
      }
    }
</script>

export const infiniteScrollMixin = {
  data() {
    return {
      page: 1,
      loadingMore: true,
    }
  },
  methods: {
    resetInfiniteScroll() {
      this.page = 1;
      this.loadingMore = true;
    },
    async loadMore(event, modelName) {
      if(!this[modelName].paginatorInfo.hasMorePages) {
        event.target.complete();
        this.loadingMore = false;
        return;
      }
      this.page++;

      let previousVariables = JSON.parse(this.$apollo.queries[modelName].previousVariablesJson);
      await this.$apollo.queries[modelName].fetchMore({
        variables: {
          ...previousVariables,
          page: this.page
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            [modelName]: {
              __typename: previousResult[modelName].__typename,
              data: [...previousResult[modelName].data, ...fetchMoreResult[modelName].data],
              paginatorInfo: fetchMoreResult[modelName].paginatorInfo
            }
          }
        },

      });

      event.target.complete();
    },
  }
}

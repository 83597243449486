export default {
  methods: {
    getLocation(location) {
      return {
        name: this.getLocationValueOrError(location, 'name'),
        latitude: this.getLocationValueOrError(location, 'latitude'),
        longitude: this.getLocationValueOrError(location, 'longitude'),
        city: this.getLocationValueOrError(location, 'city'),
        street: this.getLocationValueOrError(location, 'street'),
        state: this.getLocationValueOrError(location, 'state'),
        country: this.getLocationValueOrError(location, 'country'),
        number: this.getLocationValueOrError(location, 'number'),
        zip_code: this.getLocationValueOrError(location, 'zipcode'),
      }
    },
    getLocationValueOrError(object, name) {
      if(object[name] !== undefined) {
        return object[name];
      } else {
        throw Error(`Location ${name} is not defined.`);
      }
    }
  }
}

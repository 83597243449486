import { captureException } from "@/utils/captureException";
import { Preferences } from '@capacitor/preferences';
import { Geolocation } from '@capacitor/geolocation';
import { GEOLOCATION_REMIND_AFTER } from "@/configs";

export const setUserPositionMixin = {

  data() {
    return {
      latitude: null,
      longitude: null,
    }
  },
  methods: {
    async setUserPosition() {
      try {
        const position = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });

        if(position?.coords?.latitude) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        }
      } catch(error) {
        if(error?.code === 1) {

          await this.displayGeolocationWarning();

        }
        captureException(error);
      }
    },
    async displayGeolocationWarning() {
      const REMINDER_DELAY = 30 * 24 * 3600 * 1000; // Snooze for 30 days
      const now = Date.now();

      const storageValue = await Preferences.get({key: GEOLOCATION_REMIND_AFTER});
      const remindAfterTime = parseInt( storageValue?.value || '0' );

      if (remindAfterTime > now) {
        return;
      }

      await Preferences.set({key: GEOLOCATION_REMIND_AFTER, value: (now + REMINDER_DELAY).toString()});

      this.$ionic.toastController.create({
        color: 'danger',
        message: this.$t('youhavedisabledgeolocation'),
        showCloseButton: true,
      }).then( toast => toast.present() );
    }
  }
}

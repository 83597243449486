import Popover from '@/components/Popover'

export default {
  methods: {
    data() {
      return {
        currentPopover: null
      }
    },
    popOver(event, props = {}) {
      props.dismiss = this.dismissPopover;

      return this.$ionic.popoverController.create({
        component: Popover,
        componentProps: {
          parent: this,
          propsData: props
        },
        translucent: false,
        event
      })
        .then(
          p => {
            p.present()
            this.currentPopover = p
          }
        )
    },
    dismissPopover() {
      if(this.currentPopover) {
        this.currentPopover.dismiss().then(() => {
          this.currentPopover = null;
        })
      }
    }
  }
}

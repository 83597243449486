/**
 * This creates an auto-refresh timer with a given interval (defaults to 2 seconds)
 *
 * Use the `autoRefreshState` inside your computed property to refresh that property automatically.
 */
export const AutoRefreshTimer = {
  data() {
    return {
      autoRefreshTimer: null,
      autoRefreshInterval: 1000,
      autoRefreshState: false, // RefreshTicker will flip between true and false, and will be used to refresh our UI
      autoRefreshFunction: null,
    }
  },

  mounted() {
    this.autoRefreshTimer = setInterval(this.onAutoRefresh, this.autoRefreshInterval);
  },

  beforeDestroy() {
    clearInterval(this.autoRefreshTimer);
  },

  methods: {
    onAutoRefresh() {
      this.autoRefreshState = !this.autoRefreshState;
    }
  }
}

<template>
  <ion-page class="ion-page">
    <GoBackHeader :default-href="`/facilities/${id}`" />
    <ion-content>
      <ion-list>
        <ion-list-header>
          <ion-label>{{ $t('advertisementdetails') }}</ion-label>
        </ion-list-header>
      </ion-list>

      <div class="px-4">
        <div class="mb-4">
          <ion-label>{{ $t('interactions') }}</ion-label>
          <div style="width: 100%; height: 75vh; position: relative">
            <LineChart
              id="my-chart-impressions"
              :chart-options="chartOptions"
              :chart-data="chartData"
              style="height: 100%"
            />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { Line as LineChart } from 'vue-chartjs/legacy'
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement
  } from 'chart.js'
  import { GET_AD_ANALYTICS } from '@/graphql/queries';
  import { infiniteScrollMixin } from '@/mixins';
  import { defaultPaginationConfig } from '@/configs';

  ChartJS.defaults.backgroundColor = '#fff';
  ChartJS.defaults.color = '#fff';
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  );

  import moment from 'moment-timezone';

  import {sum, map} from 'lodash';

  export default {
    mixins: [
      infiniteScrollMixin,
    ],
    components: {
      LineChart,
    },
    props: {
      id: {
        type: String,
        required: true,
      }
    },
    data() {
      const unit = 'month';
      // We do not want to mix up UTC with CET
      const startDate = moment.tz('UTC').startOf(unit).startOf('day').subtract(6, unit);
      const endDate = moment.tz('UTC').startOf(unit).startOf('day').add(1, unit);
      const range = this.generateRange(startDate, endDate, unit);

      return {
        labelFormats: {
          month: 'MMMM',
          week: 'YYYY-W'
        },
        unit,
        startDate,
        endDate,
        range,
        chartData: {
          labels: [],
          datasets: [
            {
              label: this.$t('clicks'),
              backgroundColor: '#00FE00',
              borderColor: '#00FE00',
              data: []
            },
            {
              label: this.$t('impressions'),
              backgroundColor: '#b9b9bb',
              borderColor: '#b9b9bb',
              data: []
            },
          ]
        },
        chartOptions: {
          plugins: {
            legend: {
              display: true // This will show the legend
            }
          },
          pointRadius: 8,
          scales: {
            x: {
              ticks: {
                color: '#fff' // Set the x-axis label color to white
              },
              grid: {
                color: '#777', // Set the x-axis grid line color to grey
                display: true,
              }
            },
            y: {
              ticks: {
                color: '#fff',
              },
              grid: {
                color: '#444', // Set the y-axis grid line color to dark grey
                display: true,
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      }
    },
    watch: {
      ad_clicks(data) {
        this.chartData.datasets[0].data = this.mapAnalytics(data)
      },
      ad_impressions(data) {
        this.chartData.datasets[1].data = this.mapAnalytics(data)
      }
    },
    mounted() {
      this.chartData.labels = this.generateLabels(this.range, this.unit)
    },
    methods: {
      /**
       * Generate a range of start and end dates that will be used to form the x-axis
       *
       * @param startDate
       * @param endDate
       * @param unit
       * @returns {*[]}
       */
      generateRange(startDate, endDate, unit) {
        const currentDate = startDate.clone();
        const ranges = [];

        if (endDate.isBefore(startDate)) {
          throw new Error('End date must not be before start date')
        }

        while (currentDate.isSameOrBefore(endDate)) {
          ranges.push({
            start_time: currentDate.clone(),
            end_time: currentDate.clone().add(1, unit),
          });
          currentDate.add(1, unit)
        }

        return ranges;
      },
      /**
       * Generate chart labels for the range of dates that are spaced apart in units
       */
      generateLabels(range, unit) {
        // Generate labels for this.range
        return range.map( (range) => {
          return this.rangeLabel(range, unit)
        })

      },
      /**
       * Return a label for the x-axis
       * @param range
       * @param unit
       * @returns {*}
       */
      rangeLabel(range, unit) {
        return range.start_time.format(this.labelFormats[unit])
      },

      /**
       * Maps the output of our api to the ranges that we generated.
       *
       * @param data
       * @returns {number[]}
       */
      mapAnalytics(data) {
        const mapped = this.range.map( (range) => {
          const inRange = data.filter( i => {
            return moment.tz(i.start_time, 'UTC').isBetween(range.start_time, range.end_time)
          })
          return sum(map(inRange, 'value'))
        })

        return mapped;
      }
    },
    apollo: {
      ad_clicks: {
        query: GET_AD_ANALYTICS,
        variables() {
          return {
            ...defaultPaginationConfig,
            facility_id: this.id,
            ad_id: this.$route.params.advertisementId,
            event_type: 'click',
            start_time: this.startDate.format('YYYY-MM-DD 00:00:00'),
            end_time: this.endDate.format('YYYY-MM-DD 00:00:00'),
            unit: this.unit,
          }
        },
        update: (data) => data.getAdAnalytics
      },
      ad_impressions: {
        query: GET_AD_ANALYTICS,
        variables() {
          return {
            ...defaultPaginationConfig,
            facility_id: this.id,
            ad_id: this.$route.params.advertisementId,
            event_type: 'impression',
            start_time: this.startDate.format('YYYY-MM-DD 00:00:00'),
            end_time: this.endDate.format('YYYY-MM-DD 00:00:00'),
            unit: this.unit,
          }
        },
        update: (data) => data.getAdAnalytics
      }
    }
  }
</script>